<template>
    <ODataGrid :data="translations" hideMultiselectColumn hideActionColumn hideSystemColumn hideGridMenu noFooter>
        <OColumn field="Language" width="200"/>
        <template v-for="field in fields">
            <OColumn :field="field" :headerName="$t(field)" editable flexwidth="1">
                <template #default="{row}">
                    {{ jsonObject[field+row.LangID] }}
                </template>
                <template #editor="{row}">
                    <ScopeEditor :field="field" :lang="row.LangID" :index="row.index" v-slot="{item}">
                        <input v-model="item.value">
                    </ScopeEditor>
                </template>
            </OColumn>
        </template>
    </ODataGrid>
</template>

<script setup lang="ts">
import { ref, watch, onMounted } from 'vue';
import { getOrCreateDataObject } from 'o365-dataobject';

const props = withDefaults(defineProps<{
    modelValue?: string | object,
    fields?: string[],
    jsonSpace?: number,
    outputType?: 'string' | 'object',
}>(), {
    fields: () => ['Name', 'Title']
});

const emit = defineEmits<{
    (e: 'update:modelValue', pValue?: string | object )
}>();

const jsonString = ref('');
const jsonObject = ref<object>(null);
const invalidInput = ref(false);

const translations = ref([]);
const dsSysLanguages = getOrCreateDataObject({
    id: 'o_dsTranslateLanguages',
    viewName: 'stbl_Translate_Languages',
    fields: [
        { name: 'Name', type: 'string' },
        { name: 'LangID', type: 'number' },
        { name: 'Sorting', sortOrder: 1, sortDirection: 'asc' }
    ],
    whereClause: '[OnlyForFormatting] = 0'
});

function updateObject() {
    if (typeof jsonString.value === 'string') {
        try {
            const value = JSON.parse(jsonString.value)
            if (typeof value !== 'object') { throw new TypeError('Could not parse JSON string'); }
            jsonObject.value = value;
            invalidInput.value = false;
        } catch (error) {
            invalidInput.value = true;
        }
    } else {
        jsonObject.value = jsonString.value;
        invalidInput.value = false;
    }
};

function updateInput() {
    if (typeof props.modelValue === 'string') {
        jsonString.value = props.modelValue || '';
        updateObject();
    } else if (props.modelValue == null) {
        jsonObject.value = {};
        jsonString.value = '{}';

    } else {
        jsonObject.value = props.modelValue;
        jsonString.value = JSON.stringify(props.modelValue, undefined, props.jsonSpace);
    }
    loadSysLanugages();
}

watch(() => props.modelValue, () => {
    updateInput();
});
watch(jsonString, updateObject);
watch(jsonObject, () => {
    const emptyValue = jsonObject.value == null || Object.keys(jsonObject.value).length === 0;
    if (typeof props.modelValue === 'string') {
        emit('update:modelValue', emptyValue ? '' : JSON.stringify(jsonObject.value, undefined, props.jsonSpace));
    } else if (props.modelValue) {
        emit('update:modelValue', jsonObject.value);
    } else {
        if (emptyValue) {
            emit('update:modelValue', (props.outputType == null || props.outputType === 'string') ? '' : jsonObject.value);
        } else {
            emit('update:modelValue', (props.outputType == null || props.outputType === 'string') ? JSON.stringify(jsonObject.value, undefined, props.jsonSpace) : jsonObject.value);
        }
    }
}, { deep: true });

async function loadSysLanugages() {
    if (dsSysLanguages.data.length === 0) {
        await dsSysLanguages.load();
    }
    let jsonRows = [];
    if (jsonObject.value == null) {
        jsonObject.value = {};
    }
    dsSysLanguages.data.forEach((row, index) => {
        const jsonRow = {
                Language: row.Name,
                LangID: row.LangID,
                index: index
        }
        props.fields.forEach(field => {
            jsonRow[field] = jsonObject.value[field+row.LangID];
        });
        jsonRows.push(jsonRow);
    });
    translations.value.splice(0, translations.value.length, ...jsonRows);
}

onMounted(() => {
    updateInput();
});

function ScopeEditor(props2, ctx) {
    const wrapper = {
        get value() { return jsonObject.value[props2.field+props2.lang]},
        set value(pValue) {
            jsonObject.value[props2.field+props2.lang] = pValue;
            translations.value[props2.index][props2.field] = pValue;
        }
    };
    return ctx.slots.default({
        item: wrapper
    });
}

</script>
